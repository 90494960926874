// Styles
// CoreUI Icons Set
@import "~@coreui/icons/css/coreui-icons.css";
// Import Flag Icons Set
@import "~flag-icon-css/css/flag-icon.min.css";
// Import Font Awesome Icons Set
@import "~font-awesome/css/font-awesome.min.css";
// Import Simple Line Icons Set
@import "~simple-line-icons/css/simple-line-icons.css";
// Import Main styles for this application
@import "./scss/style.scss";
// Import Font Muli
@font-face {
  font-family: "Muli";
  src: url("//db.onlinewebfonts.com/t/862632cab65e320043b5b84c87fb5865.eot");
  src: url("//db.onlinewebfonts.com/t/862632cab65e320043b5b84c87fb5865.eot?#iefix")
      format("embedded-opentype"),
    url("//db.onlinewebfonts.com/t/862632cab65e320043b5b84c87fb5865.woff2")
      format("woff2"),
    url("//db.onlinewebfonts.com/t/862632cab65e320043b5b84c87fb5865.woff")
      format("woff"),
    url("//db.onlinewebfonts.com/t/862632cab65e320043b5b84c87fb5865.ttf")
      format("truetype"),
    url("//db.onlinewebfonts.com/t/862632cab65e320043b5b84c87fb5865.svg#Muli")
      format("svg");
}
