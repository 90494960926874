// Here you can add other styles
@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");

.header-table {
  background-color: #f47e40;
  color: white;
  //max-width: 100%;
}

@media (max-width: 990px) {
  .table-responsive {
    display: block;
  }
}

@media (min-width: 1054px) {
  .table-responsive {
    display: revert;
  }
}

.tbl {
  background-color: #f47e40;
  color: white;
}

.card-payroll {
  background-color: #278be0;
  color: white;
}

.location-search-input {
  font-family: "Lato", sans-serif;
  width: 100%;
  height: 35px;
  background: none;
  border: 1px solid black;
  border-radius: 5px;
  font-size: 0.9rem;
  color: black;
  padding: 10px;
}

.input-suggestion {
  font-family: "Lato", sans-serif;
  width: 100%;
  height: 35px;
  background: white;
  border-bottom: 1px black dotted;
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  color: black;
}

.customDatePickerWidth,
.customDatePickerWidth > div.react-datepicker-wrapper,
.customDatePickerWidth
  > div
  > div.react-datepicker__input-container
  .customDatePickerWidth
  > div
  > div.react-datepicker__input-container
  input {
  width: 100%;
}

.TransitionRow-enter {
  opacity: 0.01;
}

.TransitionRow-enter.TransitionRow-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.TransitionRow-leave {
  opacity: 1;
}

.TransitionRow-leave.TransitionRow-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

.e-event-save,
.e-event-delete {
  display: none;
}

.berlangganan{
}
.berlangganan:hover {
  background-color: #278be0 !important;
  color: white !important;
}

.style-berlangganan {
  background-color: #1890ff26;
  border-color: #1890ff;
  color: #1890ff
}

.reguler:hover{
  background-color: #f79d6d !important;
  color: white !important;
}

.style-reguler {
  background-color: #f17d401c;
  border-color: #f17d40;
  color: #f17d40;
}

.photo-user-circle{
  border-radius: 50%;
  width: 35px;
  height: 35px;
  object-fit: cover;
}


/* Wrapper tab */
#rootTab {
  font-size: 14px;
  .nav-link {
    // flex: 1 !important;
    cursor: pointer;
    color: #666666;
    font-weight: 600;
    font-size: 14px;
  }
  .navWrapper {
    display: flex;
    flex-direction: column;
  }

  .activeTitle {
    color: #63c2de;
    // border-bottom: 1px solid #63c2de;
    font-size: 16px;
  }

  .sub-title {
    font-size: 14px;
  }
}

.jumbotron-component {
  background-color: white !important;
  padding: 5px 15px;
  border-radius: 5px;

  .jumbotron-subtitle {
    color: grey;
    font-size: 16px;
  }

  .jumbotron-title {
    font-size: 28px;
    font-weight: 500;
    margin-top: 35px;
    margin-bottom: 20px;
  }
}

@media (max-width: 480px) {
  .Button1 {
    display: none;
  }
  .container {
    margin: 0;
    padding: 0;
  }
  .SearchInput {
    max-width: 140%;
  }
  .ButtonDiv {
    margin-top: 50px;
  }
}

.fontCheckpoint {
  padding: 0;
}

.fontTitle {
  font-size: 14px;
  font-weight: 700;
}

.long-title h3 {
  font-family: 'Segoe UI Light', 'Helvetica Neue Light', 'Segoe UI', 'Helvetica Neue', 'Trebuchet MS', Verdana;
  font-weight: 200;
  font-size: 28px;
  text-align: center;
  margin-bottom: 20px;
}
